const setMobileActiveStateListener = () => {
    // Get the hamburger button element
    const hamburger = document.getElementById('hamburger') as HTMLButtonElement

    const mm_toggle_elements = [
        document.body,
        document.querySelector('aside.mobile_controls') as HTMLElement,
        document.querySelector('nav.mobile') as HTMLElement,
    ]

    // Add click event listener to the hamburger button
    hamburger.addEventListener('click', _ => {
        // Toggle the 'is-active' class for each element
        hamburger.classList.toggle('is-active')
        mm_toggle_elements.forEach(
            el =>
                (el.dataset.mmActive =
                    el.dataset.mmActive === 'true' ? 'false' : 'true')
        )
    })
}

// const setCopyrights = () => {
//     // Get all elements with 'data-copyright' attribute
//     const copyrights: NodeListOf<HTMLElement> =
//         document.querySelectorAll('[data-copyright]')

//     // Set the inner text of each copyright element to the COPYRIGHT value from the environment
//     copyrights.forEach((el: HTMLElement) => {
//         el.innerText = env.COPYRIGHT
//     })
// }

const setPhoneCallListener = () => {
    // Get all elements with 'data-phone-number' attribute
    const callButtons: NodeListOf<HTMLElement> = document.querySelectorAll(
        '[data-phone-number]'
    )

    // Add a click event listener to each button
    callButtons.forEach((button: HTMLElement) => {
        button.addEventListener('click', _ => {
            // Get the phone number from the 'data-phone-number' attribute
            const phoneNumber = button.getAttribute('data-phone-number')

            // Call the phone number
            window.open(`tel:${phoneNumber}`)
        })
    })
}

const isLongTestimonial = () => {
    const max_length = 180

    const review = document.querySelector('footer blockquote.testimonial')
    const size = review?.getAttribute('data-count')

    if (size && parseInt(size) > max_length) review?.classList.add('long')
}

;(() => {
    // Set the mobile active state listener
    setMobileActiveStateListener()

    // Set the COPYRIGHT value from the environment to each element with 'data-copyright' attribute
    // setCopyrights()

    // Set the phone call listener for each element with 'data-phone-number' attribute
    setPhoneCallListener()

    // Adjust Footer Testimonial CSS
    isLongTestimonial()

    // Set the focal point of each image
    // setImageFocalPoints()
})()
